import './createMatch.css';
import React from 'react';
import HeaderComp from './HeaderComp';
import twitterIcon from '../assets/face-icon.png';
import faceIcon from '../assets/face-icon.png';
import youtubeIcon from '../assets/youtube-icon.png';
import createMatchPay from './functions/createMatch';
import { useSDK } from "@metamask/sdk-react";

const CreateMatch = (() => {
    const { sdk } = useSDK();

    const handleCreateMatch = (async (event) => {
        event.preventDefault();
        const getProviderObj = sdk?.getProvider();
        let getReferCode = event.target[1].value;
        let optionSelected = '';
        let countOption = 0;
        let selectIndex;

        for (let i = 2; i < 5; i++) {
            const checkValue = event.target[i];
            if (checkValue.checked) {
                countOption++;
                selectIndex = i;
            }
        }

        if (countOption === 1) {
            optionSelected = event.target[selectIndex].value;

        } else {
            alert('Please select one option.');
        }

        if (getReferCode === '') {
            getReferCode = 'x';
        }

        if (optionSelected !== '') {
            const accounts = await sdk?.connect();
            const getAccCurr = accounts[0];
            createMatchPay(event, optionSelected, getProviderObj, getAccCurr, getReferCode);
        }
    });

    return (
        <div className="flex flex-col">
            <HeaderComp />
            <section id="create-bg" className="flex flex-col min-w-full">
                <article id="" className="bg-blackOne rounded-2xl mx-20 font-sans px-8 py-20 mt-20">
                    <form className="max-w-lg mx-auto" onSubmit={handleCreateMatch}>
                        <div className="mb-5">
                            <input
                                type="text"
                                id="text-tag"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Enter Game Tag Name"
                                required
                            />
                        </div>
                        <div className="mb-5">
                            <input
                                type="text"
                                id="text-referral"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Enter referral CODE"
                            />
                        </div>
                        <div id="bets-container" className="flex items-start mb-5">
                            <div className="bet-container">
                                <div className="flex items-center h-5">
                                    <input
                                        id="remember"
                                        type="checkbox"
                                        value="0.018"
                                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                    />
                                </div>
                                <label id="bet-bera" className="ms-2 max-md:text-xs text-sm font-medium text-gray-400 dark:text-gray-500 mr-4">
                                    0.015 BNB
                                </label>
                            </div>
                            <div className="bet-container">
                                <div className="flex items-center h-5">
                                    <input
                                        id="remember"
                                        type="checkbox"
                                        value="0.037"
                                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                    />
                                </div>
                                <label id="bet-bera" className="ms-2 max-md:text-xs text-sm font-medium text-gray-400 dark:text-gray-500 mr-4">
                                    0.03 BNB
                                </label>
                            </div>
                            <div className="bet-container">
                                <div className="flex items-center h-5">
                                    <input
                                        id="remember"
                                        type="checkbox"
                                        value="0.055"
                                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                    />
                                </div>
                                <label id="bet-bera" className="ms-2 max-md:text-xs text-sm font-medium text-gray-400 dark:text-gray-500">
                                    0.045 BNB
                                </label>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="text-white bg-redOne hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Create A Match
                        </button>
                    </form>
                </article>
                <footer id="footer-container" className="flex flex-col text-sm mx-20 mt-40 mb-40">
                    <div id="footer-top" className="flex justify-between mb-10">
                        <div id="footer-menu" className="text-white opacity-70">
                            <a href="/" className="mr-6">Home</a>
                            <a href="/" className="mr-6">Documentation</a>
                            <a href="/" className="mr-6">Features</a>
                            <a href="/" className="mr-6">Play Games</a>
                        </div>
                        <div className="flex">
                            <img
                                src={twitterIcon}
                                alt="Follow us on Twitter"
                                width={24}
                                height={24}
                                className="mr-10"
                            />
                            <img
                                src={faceIcon}
                                alt="Follow us on Twitter"
                                width={24}
                                height={24}
                                className="mr-10"
                            />
                            <img
                                src={youtubeIcon}
                                alt="Follow us on Twitter"
                                width={24}
                                height={24}
                            />
                        </div>
                    </div>
                    <hr id="hr-footer" className="border-1 opacity-20" />
                    <div id="footer-bottom" className="flex justify-between mt-10">
                        <span className="text-white opacity-60">
                            © 2024 GAP. All rights reserved.
                        </span>
                        <div className="text-white opacity-70">
                            <a href="/" className="mr-10">Terms of Service</a>
                            <a href="/">Privacy Policy</a>
                        </div>
                    </div>
                </footer>
            </section>
        </div>
    )
})

export default CreateMatch;