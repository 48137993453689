import React from 'react';
import twitterIcon from '../assets/face-icon.png';
import faceIcon from '../assets/face-icon.png';
import youtubeIcon from '../assets/youtube-icon.png';
import './createMatch.css';
import HeaderComp from './HeaderComp';

const Docs = (() => {

    return (
        <div className="flex flex-col">
            <HeaderComp />
            <section id="create-bg" className="flex flex-col min-w-full">
                <article className="bg-blackOne mx-20 mt-20 px-20 py-8 rounded-3xl">
                    <div className="flex flex-col">
                        <h1 className="font-sans text-white max-md:text-xl text-3xl mb-4">Steps to get started:</h1>
                        <ol className="font-sans text-white max-md:text-xs text-sm list-decimal list-inside leading-6">
                            <li className="mb-2">
                                As a new user you will need to install Meta-mask if you do not have one. If you do,
                                simply connect your MM wallet and make sure you are on the Binance Network.
                            </li>
                            <li className="mb-2">
                                Once you're connected to your Meta Mask wallet you can queue up a game with an opponent,
                                there are 3 wager amounts [0.015 BNB, 0.03 BNB, 0.045 BNB].
                            </li>
                            <li className="mb-2">
                                Once you have queued up a match, you must wait for any player to join your match,
                                if you are looking for someone to play you can head to our discord
                                <a
                                    href="https://discord.gg/hxNuTWMYEM"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="break-words text-blue-500"
                                > https://discord.gg/hxNuTWMYEM </a>
                                to ask if anyone wants to join and send your room code for them to join.
                                After a match begins you can use the on app chat room to speak to each other.
                            </li>
                            <li className="mb-2">
                                If you create a match and no player joins you can always check the created open matches and join
                                an existing game room.
                            </li>
                            <li className="mb-2">
                                The tab “My matches” menu is where you can cancel a game match that you may have joined.
                            </li>
                            <li className="mb-2">
                                The Final step players will need to click the button box CHECK FINAL RESULT
                                to update the result and then click on PAYMENT Button to disperse the reward
                            </li>
                            <div>
                                GAP has a built in fee for our platform. This fee is 20% of the winning wager and a 20%
                                fee for the losing players wager.
                            </div>
                        </ol>
                    </div>
                </article>
                <article className="bg-blackOne mx-20 mt-20 px-20 py-8 rounded-3xl">
                    <div className="flex flex-col">
                        <h2 className="font-sans text-white max-md:text-xl text-3xl mb-4">Rules of the game:</h2>
                        <ol className="font-sans text-white max-md:text-xs text-sm list-decimal list-inside leading-6">
                            <li className="mb-2">
                                It is critical that every match has a screen shot for a clear winner.
                                Reason is because on Steam there is no API to call in game data for who won the match so how we verify is done by a central
                                team check for matches that have completed and players have marked the match as completed.
                                If a player leaves a match mid playing (Rage Quit) this will be considered a loss on that player's end and will be paid out to the winner.
                            </li>
                            <li className="mb-2">
                                A big part of this is making sure that players don't come in to play and try to earn but habitually quits matches before matches are finished,
                                if we find out that a player does this we will ban your account.
                            </li>
                            <li className="mb-2">
                                Cheating is 100% not tolerated. If we find out that someone has been cheating,
                                the player's IP address will be banned permanently.
                            </li>
                            <li className="mb-2">
                                There are no exceptions here, this is meant to be a 100% legal and fair game
                            </li>
                            <li className="mb-2">
                                Very important, after a match has ended both players need to update the final result Win/Lose,
                                and if the players haven't updated after 20 min,
                                the wagers will be paid back to players minus our fee.
                            </li>
                            <div>
                                What Is GAP
                                <br />
                                GAP is a game changing dApp set to disrupt the WEB3 gaming landscape.
                                Allowing players to play and wager on games they already know and love.
                                This is the missing link in connecting to the worlds of gaming.
                                We are bringing a fresh approach to how players experience gaming with our PLAY-AND-EARN platform.
                                GAP completely lowers the barrier of entry for players to earn crypto playing AAA title games.
                                All you need is a Steam account and a Meta Mask wallet. It doesn't get simpler than that.
                            </div>
                        </ol>
                    </div>
                </article>
                <footer id="footer-container" className="flex flex-col text-sm mx-20 mt-40 mb-40">
                    <div id="footer-top" className="flex justify-between mb-10">
                        <div id="footer-menu" className="text-white opacity-70">
                            <a href="/" target="_blank" rel="noreferrer" className="mr-6">Home</a>
                            <a href="/" target="_blank" rel="noreferrer" className="mr-6">Documentation</a>
                            <a href="/" target="_blank" rel="noreferrer" className="mr-6">Features</a>
                            <a href="/" target="_blank" rel="noreferrer" className="mr-6">Play Games</a>
                        </div>
                        <div className="flex">
                            <img
                                src={twitterIcon}
                                alt="Follow us on Twitter"
                                width={24}
                                height={24}
                                className="mr-10"
                            />
                            <img
                                src={faceIcon}
                                alt="Follow us on Twitter"
                                width={24}
                                height={24}
                                className="mr-10"
                            />
                            <img
                                src={youtubeIcon}
                                alt="Follow us on Twitter"
                                width={24}
                                height={24}
                            />
                        </div>
                    </div>
                    <hr id="hr-footer" className="border-1 opacity-20" />
                    <div id="footer-bottom" className="flex justify-between mt-10">
                        <span className="text-white opacity-60">
                            © 2024 GAP. All rights reserved.
                        </span>
                        <div className="text-white opacity-70">
                            <a href="/" target="_blank" rel="noreferrer" className="mr-10">Terms of Service</a>
                            <a href="/" target="_blank" rel="noreferrer">Privacy Policy</a>
                        </div>
                    </div>
                </footer>
            </section>
        </div>
    )
})

export default Docs;