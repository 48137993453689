import { ethers } from 'ethers';
import contractABI from '../abi/contractABI.json';

const cancelMatch = (async (event, getProviderObj, getAccCurr, getMatchCreator, getCreatorMatchId) => {
    event.preventDefault();
    const contractAddress = process.env.REACT_APP_TO_CONTRACT_ADDRESS;
    // const getGameID = event.target[0].value;
    const toLowergetAccCurr = getAccCurr.toLowerCase();
    const toLowergetMatchCreator = getMatchCreator.toLowerCase();

    // console.log('toLowergetAccCurr', toLowergetAccCurr);
    // console.log('toLowergetMatchCreator', toLowergetMatchCreator);
    // console.log('getCreatorMatchId', getCreatorMatchId);

    try {
        const getProvider = new ethers.providers.Web3Provider(getProviderObj);
        const signer = getProvider.getSigner();
        const chainId = (await getProvider.getNetwork()).chainId;

        if (chainId === 56) {
            const contractGap = new ethers.Contract(contractAddress, contractABI, signer);

            // Send the transaction
            if (toLowergetAccCurr === toLowergetMatchCreator) {
                const tx = await contractGap.cancelMatch(getCreatorMatchId);

                // Wait for the transaction to be mined
                const getTransHash = await tx.wait();
                console.log('getTransHash', getTransHash);

                if (getTransHash.status === 1) {
                    localStorage.removeItem('mymatches');
                    localStorage.removeItem('matches');
                    setTimeout(window.location.reload(), 10000);
                }
            }
        } else {
            alert('Please change to Binance Network');
        }

    } catch (error) {
        console.log('Error submitting the payment');
    }
});

export default cancelMatch;