const readCreateMatches = (async (getProviderObj, ethers, contractABI, contractAddress, account) => {

    try {
        const getProvider = new ethers.providers.Web3Provider(getProviderObj);
        const signer = getProvider.getSigner();
        const contractGap = new ethers.Contract(contractAddress, contractABI, signer);
        const getAllMatches = await contractGap.getAllMatches();
        // console.log('getAllMatches', getAllMatches);
        const arrayMatches = [];

        for (let i = 0; i < getAllMatches.length; i++) {
            let matchObject = {};
            // console.log('getAllMatches', getAllMatches);
            const getGameID = getAllMatches[i].details.gameID;
            const getReferral = getAllMatches[i].details.referralID;
            const getBetAmount = getAllMatches[i].details.betAmount;
            const getBetAmountHex = ethers.utils.hexValue(getBetAmount);
            const getBetAmountNum = parseInt(Number(getBetAmountHex));
            const trimBalance = (getBetAmountNum / 1000000000000000);
            const showBalance = (trimBalance / 1000).toFixed(3);
            const getMatchDate = getAllMatches[i].details.matchDate;
            const timestamp = ethers.BigNumber.from(getMatchDate).toNumber();
            const dateMatch = new Date(timestamp * 1000);
            const getClaimedChat = getAllMatches[i].details.claimed;
            const getCreatorAddr = getAllMatches[i].state.creator;
            const trimCreatorAddr = getAllMatches[i].state.creator.slice(0, 10);
            const getMatchId = getAllMatches[i].matchID;
            const convMatchIdHex = ethers.utils.hexValue(getMatchId);
            const getMatchIDValue = parseInt(Number(convMatchIdHex));
            const getAdversary = getAllMatches[i].state.adversary;
            const getStatusChat = getAllMatches[i].state.chatRoom;
            matchObject.creatorAddress = getCreatorAddr;
            matchObject.getCreatorAddr = trimCreatorAddr;
            matchObject.getGameID = getGameID;
            matchObject.getReferral = getReferral;
            matchObject.showBalance = showBalance;
            matchObject.dateMatch = dateMatch;
            matchObject.getClaimedChat = getClaimedChat;
            matchObject.getMatchIDValue = getMatchIDValue;
            matchObject.getAdversary = getAdversary;
            matchObject.getStatusChat = getStatusChat;
            arrayMatches.push(matchObject);
        }
        localStorage.setItem('matches', JSON.stringify(arrayMatches));

    } catch (error) {
        console.log('Error loading Matches to Join');
    }
});

export default readCreateMatches;