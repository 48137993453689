import React, { useState } from 'react';
import { useSDK } from "@metamask/sdk-react";
import { ethers } from 'ethers';

const MetamaskComp = (() => {
    const { sdk } = useSDK();
    const [currBalance, setCurrBalance] = useState('0.00');
    // const { ethereum } = window;
    // const CheckMetaExt = ethereum.isMetaMask;
    // const checkConnect = ethereum.isConnected();
    const getProviderObj = sdk?.getProvider();
    // const bartioChainId = '0x138d4';

    if (getProviderObj !== 'undefined' && getProviderObj !== undefined) {
        getProviderObj.on('chainChanged', () => {

            localStorage.removeItem('mymatches');
            localStorage.removeItem('matches');
            setTimeout(window.location.reload(), 3000);
            // window.location.reload();
        });

        getProviderObj.on('accountsChanged', () => {
            localStorage.removeItem('mymatches');
            localStorage.removeItem('matches');
            setTimeout(window.location.reload(), 3000);
        });
    }

    const initMetaMask = async () => {

        try {
            const accounts = await sdk?.connect();
            const getAccCurr = accounts[0];
            const provider = new ethers.providers.Web3Provider(getProviderObj);
            const chainId = (await provider.getNetwork()).chainId;

            if (chainId === 56) {
                const mainAccBalance = await provider.getBalance(getAccCurr);
                const selectvHexValue = ethers.utils.hexValue(mainAccBalance);
                const convertHexToDec = parseInt(Number(selectvHexValue));
                const trimBalance = (convertHexToDec / 1000000000000000);
                const showBalance = (trimBalance / 1000).toFixed(2);
                setCurrBalance(showBalance);

            } else {
                alert('Please change to Binance Network');
            }

        } catch (error) {
            console.error('Error initializing MetaMask:', error);
        }
    };

    return <button type="button" onClick={initMetaMask}>METAMASK {currBalance}</button>
});

export default MetamaskComp;