import { ethers } from 'ethers';
import contractABI from '../abi/contractABI.json';

const payJoinMatch = (async (event, amount, provider, getMatchId, getCreatorAddress, getGameIdAdversary) => {
    event.preventDefault();
    const contractAddress = process.env.REACT_APP_TO_CONTRACT_ADDRESS;
    // const getGameID = event.target[0].value;
    // console.log('getGameID', getGameID);
    // console.log('account', account);

    let checkAmount = '';
    // let amountInHex = '';
    // console.log('amount', amount);

    if (amount === '0.018') {
        checkAmount = 0.018;
        // amountInHex = '0x429d069189e0000';

    } else if (amount === '0.037') {
        checkAmount = 0.037;
        // amountInHex = '0x1c6bf52634000';

    } else if (amount === '0.055') {
        checkAmount = 0.055;
        // amountInHex = '0x38d7ea4c68000';
    }

    try {
        const getProvider = new ethers.providers.Web3Provider(provider);
        const signer = getProvider.getSigner();
        const chainId = (await getProvider.getNetwork()).chainId;

        if (chainId === 56) {

            if (getCreatorAddress !== getGameIdAdversary) {
                const contractGap = new ethers.Contract(contractAddress, contractABI, signer);

                // Define the bera_amount in ethers (assuming bera_amount is already in ethers)
                const beraAmountInWei = ethers.utils.parseEther(checkAmount.toString());

                // console.log('beraAmountInWei', beraAmountInWei);

                // Send the transaction
                const tx = await contractGap.joinMatch(getCreatorAddress, getMatchId, getGameIdAdversary, {
                    value: beraAmountInWei
                });

                // Wait for the transaction to be mined
                const getTransHash = await tx.wait();
                console.log('getTransHash', getTransHash);
                localStorage.removeItem('mymatches');

                if (getTransHash.status === 1) {
                    window.location.reload();
                }
            } else {
                alert('You cant join to your own game');
            }
        } else {
            alert('Please change to Binance Network');
        }

    } catch (error) {
        console.log('Error submitting the payment');
        alert('Balance doesnt have enugh BNB for transaction');
    }
});

export default payJoinMatch;