import { ethers } from 'ethers';
import contractABI from '../abi/contractABI.json';

const createMatchPay = (async (event, amount, provider, account, referral) => {
  event.preventDefault();
  const contractAddress = process.env.REACT_APP_TO_CONTRACT_ADDRESS;
  const getGameID = event.target[0].value;
  // console.log('getGameID', getGameID);

  let checkAmount = '';
  // let amountInHex = '';

  if (amount === '0.018') {
    checkAmount = 0.018;
    // amountInHex = '0x429d069189e0000';

  } else if (amount === '0.037') {
    checkAmount = 0.037;
    // amountInHex = '0x1c6bf52634000';

  } else if (amount === '0.055') {
    checkAmount = 0.055;
    // amountInHex = '0x38d7ea4c68000';
  }

  try {
    const getProvider = new ethers.providers.Web3Provider(provider);
    const signer = getProvider.getSigner();
    const chainId = (await getProvider.getNetwork()).chainId;
    // console.log('chainId', chainId);

    if (chainId === 56) {
      const contractGap = new ethers.Contract(contractAddress, contractABI, signer);

      // Define the bera_amount in ethers (assuming bera_amount is already in ethers)
      const beraAmountInWei = ethers.utils.parseEther(checkAmount.toString());

      // Send the transaction
      const tx = await contractGap.createMatch(getGameID, referral, {
        value: beraAmountInWei
      });

      // Wait for the transaction to be mined
      const getTransHash = await tx.wait();
      console.log('getTransHash', getTransHash);
      // localStorage.removeItem('matches');

      if (getTransHash.status === 1) {
        setTimeout(window.location.reload(), 5000);
      }

    } else {
      alert('Please change to Binance Network');
    }

  } catch (error) {
    console.log('Error submitting the payment');
    alert('Balance doesnt have enugh BNB for transaction or user rejected Metamask transaction');
  }
});

export default createMatchPay;