// src/ChatRoom.js
import React, { useState, useEffect } from 'react';
// import { db } from './firebase/compat/app';
import { projectFireStore } from './firebase/config';
import firebase from 'firebase/compat/app';

const ChatRoom = () => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [userName, setUserName] = useState('');

    // Fetch messages in real-time from Firestore
    useEffect(() => {
        const unsubscribe = projectFireStore
            .collection('messages')
            .orderBy('timestamp', 'asc')
            .onSnapshot((snapshot) =>
                setMessages(snapshot.docs.map((doc) => doc.data()))
            );
        return () => unsubscribe();
    }, []);

    const sendMessage = (e) => {
        e.preventDefault();

        if (inputMessage.trim() === '') return;

        // Add message to Firestore
        projectFireStore.collection('messages').add({
            text: inputMessage,
            user: userName,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });

        setInputMessage('');
    };

    const handleInput = (e) => {
        e.preventDefault();
        setUserName(e.target.value);
    };

    return (
        <div style={{ maxWidth: '600px', margin: '0 auto' }}>
            {!userName && (
                <div>
                    <input
                        placeholder="Enter your name"
                        onChange={(e) => handleInput(e)}
                    />
                    <button onClick={() => setUserName(userName)}>Join Chat</button>
                </div>
            )}

            {userName && (
                <>
                    <div
                        style={{
                            border: '1px solid black',
                            height: '80px',
                            overflowY: 'scroll',
                            padding: '10px',
                        }}
                    >
                        {messages.map((msg, idx) => (
                            <div
                                key={idx}
                                style={{
                                    textAlign: msg.user === userName ? 'right' : 'left',
                                    marginBottom: '10px',
                                }}
                            >
                                <strong>{msg.user}:</strong> {msg.text}
                            </div>
                        ))}
                    </div>

                    <form onSubmit={sendMessage}>
                        <input
                            placeholder="Type a message..."
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            style={{ width: '80%', color: 'black' }}
                        />
                        <button type="submit" style={{ width: '18%' }}>
                            Send
                        </button>
                    </form>
                </>
            )}
        </div>
    );
};

export default ChatRoom;