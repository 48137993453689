import React, { useState, useEffect } from 'react';
import twitterIcon from '../assets/face-icon.png';
import faceIcon from '../assets/face-icon.png';
import youtubeIcon from '../assets/youtube-icon.png';
import HeaderComp from './HeaderComp';
import { useSDK } from "@metamask/sdk-react";
import { ethers } from 'ethers';
import contractABI from './abi/contractABI.json';
import readMyMatches from './functions/readMyMatches';
import submitResult from './functions/submitResult';
import payTheWinner from './functions/payTheWinner';
import cancelMatch from './functions/cancelMatch';
import ChatRoom from './ChatRoom';
import './createMatch.css';

const MyMatches = (() => {
    const { sdk } = useSDK();
    const [reloadData, setReloadData] = useState(false);
    const [storeData, setStoreData] = useState([]);
    const getProviderObj = sdk?.getProvider();
    const contractAddress = process.env.REACT_APP_TO_CONTRACT_ADDRESS;

    const readMatchesAsync = (async () => {
        const accounts = await sdk?.connect();
        // console.log('accounts', accounts);
        if (accounts !== undefined) {
            const getAccCurr = accounts[0];
            // console.log('getAccCurr', getAccCurr);
            readMyMatches(getProviderObj, ethers, contractABI, contractAddress, getAccCurr);
            setReloadData(true);
        }
    });
    readMatchesAsync();

    useEffect(() => {
        try {
            if (reloadData) {
                const result = JSON.parse(localStorage.getItem('mymatches'));
                if (result !== null) {
                    // console.log('result', result);
                    setStoreData(result);  // Set data once the promise is fulfilled

                } else {
                    setTimeout(() => {
                        const result = JSON.parse(localStorage.getItem('mymatches'));
                        // console.log('result', result);
                        setStoreData(result);  // Set data once the promise is fulfilled
                    }, 3000);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, [reloadData]);

    // console.log('storeData', storeData);

    const hadnleSubmitResult = ((event) => {
        event.preventDefault();
        // console.log('event', event);
        const getTextResult = event.target[0].value;
        // console.log('getTextResult', getTextResult);
        const getMatchId = event.target.title;
        // console.log('getMatchId', getMatchId);
        const getCreatorAddress = event.target.id;
        // console.log('getCreatorAddress', getCreatorAddress);
        const accounts = sdk?.connect();
        accounts.then((currAccount) => {
            const getAccCurr = currAccount[0];
            submitResult(event, getProviderObj, getMatchId, getCreatorAddress, getAccCurr, getTextResult);
        });
    });

    const handlePayWinner = ((event) => {
        event.preventDefault();
        // address creator, uint256 matchID
        // console.log('event', event);
        const getAddresssCreator = event.target.id;
        // console.log('getAddresssCreator', getAddresssCreator);
        const getCreatorMatchId = event.target.title;
        // console.log('getCreatorMatchId', getCreatorMatchId);
        const validateWinner = event.target.value;
        // console.log('validateWinner', validateWinner);

        if (validateWinner === 'Creator Wins' || validateWinner === 'Adversary Wins') {
            payTheWinner(event, getProviderObj, getAddresssCreator, getCreatorMatchId);
        } else {
            alert('You didnt win');
        }
    });

    const handleCancel = ((event) => {
        event.preventDefault();
        // address creator, uint256 matchID
        // console.log('event', event);
        const getAdversaryGameId = event.target.value;
        // console.log('getAdversaryGameId', getAdversaryGameId);
        const getCreatorMatchId = event.target.title;
        // console.log('getCreatorMatchId', getCreatorMatchId);
        const getMatchCreator = event.target.id;
        // console.log('getMatchCreator', getMatchCreator);
        if (getAdversaryGameId === '') {
            const accounts = sdk?.connect();
            accounts.then((currAccount) => {
                const getAccCurr = currAccount[0];
                // console.log('getAccCurr', getAccCurr);
                cancelMatch(event, getProviderObj, getAccCurr, getMatchCreator, getCreatorMatchId);
                // cancelMatch(event, getProviderObj, getAddresssCreator, getCreatorMatchId);
            });
        } else {
            alert('Match started cannot be cancelled');
        }
    });

    return (
        <div className="flex flex-col">
            <HeaderComp />
            <section id="create-bg" className="flex flex-col min-w-full">
                <article className="bg-blackOne mx-20 mt-20 p-6 rounded-3xl overflow-auto">
                    <div className="flex justify-between text-white">
                        <span>Activity</span>
                        <span>Sort:</span>
                    </div>
                    <table className="w-full border-separate border-spacing-y-3">
                        <thead>
                            <tr className="text-white text-xs">
                                <th className="text-left">Cancel Match</th>
                                <th className="text-left">Creator Address</th>
                                <th className="text-left">Game ID</th>
                                <th className="text-left">Match Date/Time</th>
                                <th className="text-left">Adversary</th>
                                <th className="text-left">Bet Amount</th>
                                <th className="text-center">Enter Result</th>
                                <th className="text-left">Final Result</th>
                                <th className="text-left">Check Payment</th>
                                <th className="text-left">Chat Room</th>
                            </tr>
                        </thead>
                        <tbody>
                            {storeData.length > 0 &&
                                storeData.map((match, index) =>
                                    <tr className="bg-purpTable text-white text-xs h-16" key={index}>
                                        <td className="p-4 rounded-l-lg">
                                            <button
                                                type="submit"
                                                onClick={handleCancel}
                                                value={match.getGameIDJoin}
                                                title={match.getMatchId}
                                                id={match.creatorAddress}
                                                className="flex items-center rounded-md text-white text-xs px-4 text-center bg-redOne hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                Cancel
                                            </button>
                                        </td>
                                        <td className="p-4">{match.getCreatorAddr}</td>
                                        <td className="p-4">{match.getGameIDCreate}</td>
                                        <td className="p-4">{match.dateMatch.slice(0, match.dateMatch.length - 5)}</td>
                                        <td className="p-4">{match.getGameIDJoin}</td>
                                        <td className="p-4">{match.getUserBetAmnt}</td>
                                        <td className="p-4">
                                            <form
                                                className="flex"
                                                onSubmit={hadnleSubmitResult}
                                                title={match.getMatchId}
                                                id={match.creatorAddress}
                                            >
                                                <div className="ml-2">
                                                    <input
                                                        type="text"
                                                        id="text-join"
                                                        className="bg-gray-50 w-24 p-2.5 text-xs border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        placeholder="Win/Lose"
                                                        required
                                                    />
                                                </div>
                                                <button
                                                    type="submit"
                                                    className="flex items-center rounded-r-md text-white text-xs px-4 text-center bg-redOne hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                    Submit
                                                </button>
                                            </form>
                                        </td>
                                        <td className="p-4">{match.getWinner}</td>
                                        <td className="p-4">
                                            <button
                                                type="submit"
                                                onClick={handlePayWinner}
                                                title={match.getMatchId}
                                                id={match.creatorAddress}
                                                value={match.getWinner}
                                                className="flex items-center rounded-md text-white text-xs px-4 text-center bg-redOne hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                                Submit
                                            </button>
                                        </td>
                                        <td className="p-4 rounded-r-lg">
                                            {storeData.length > 0 &&
                                                <ChatRoom />
                                            }
                                            {storeData.length <= 0 &&
                                                <ChatRoom />
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                            {storeData.length <= 0 &&
                                <tr className="bg-purpTable text-white text-xs h-16">
                                    <td className="p-4 rounded-l-lg"></td>
                                    <td className="p-4"></td>
                                    <td className="p-4"></td>
                                    <td className="p-4"></td>
                                    <td className="p-4"></td>
                                    <td className="p-4"></td>
                                    <td className="p-4">
                                        <form
                                            className="flex"
                                        >
                                            <div className="ml-2">
                                            </div>
                                        </form>
                                    </td>
                                    <td className="p-4"></td>
                                    <td className="p-4">
                                    </td>
                                    <td className="p-4 rounded-r-lg">
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </article>
                <footer id="footer-container" className="flex flex-col text-sm mx-20 mt-40 mb-40">
                    <div id="footer-top" className="flex justify-between mb-10">
                        <div id="footer-menu" className="text-white opacity-70">
                            <a href="/" className="mr-6">Home</a>
                            <a href="/" className="mr-6">Documentation</a>
                            <a href="/" className="mr-6">Features</a>
                            <a href="/" className="mr-6">Play Games</a>
                        </div>
                        <div className="flex">
                            <img
                                src={twitterIcon}
                                alt="Follow us on Twitter"
                                width={24}
                                height={24}
                                className="mr-10"
                            />
                            <img
                                src={faceIcon}
                                alt="Follow us on Twitter"
                                width={24}
                                height={24}
                                className="mr-10"
                            />
                            <img
                                src={youtubeIcon}
                                alt="Follow us on Twitter"
                                width={24}
                                height={24}
                            />
                        </div>
                    </div>
                    <hr id="hr-footer" className="border-1 opacity-20" />
                    <div id="footer-bottom" className="flex justify-between mt-10">
                        <span className="text-white opacity-60">
                            © 2024 GAP. All rights reserved.
                        </span>
                        <div className="text-white opacity-70">
                            <a href="/" className="mr-10">Terms of Service</a>
                            <a href="/">Privacy Policy</a>
                        </div>
                    </div>
                </footer>
            </section>
        </div>
    )
})

export default MyMatches;