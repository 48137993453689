import React from 'react';
import { useLocation } from 'react-router-dom';
import DropdownMenu from './DropDownMenu';
import iconGAP from '../assets/main-logo.png';
import MetamaskComp from './MetamaskComp';

const HeaderComp = (() => {
    const location = useLocation().pathname.split('/')[1];
    let getHeight = '844px';

    if (location === '') {

    } else if (location === 'create-match' || location === 'join-matches' || location === 'my-matches' || location === 'docs') {
        getHeight = '614px';
    }

    const GetPathName = (() => {
        // console.log('location', location);

        if (location === '') {

            return (
                <section id="create-join-games" className="flex flex-col font-sans mx-20 mb-36">
                    <h3 className="text-white max-lg:text-4xl max-md:text-2xl text-5xl">Gaming Access Portal</h3>
                    <article id="create-join-btn" className="flex justify-between mt-10 w-2/5">
                        <a href={'/create-match'} >
                            <button type="button" className="text-white bg-redOne rounded-lg mr-20 p-4 w-64">
                                <span>Create a Match</span>
                            </button>
                        </a>
                        <a href={'/join-matches'} >
                            <button type="button" className="text-white bg-redOne rounded-lg p-4 w-64">
                                <span>Join A Match</span>
                            </button>
                        </a>
                    </article>
                </section>
            )
        } else if (location === 'create-match') {

            return (
                <section id="create-page" className="flex flex-col font-sans mx-20 mb-36">
                    <h3 className="text-white max-lg:text-4xl max-md:text-4xl text-5xl">Create A Match</h3>
                </section>
            )
        } else if (location === 'join-matches') {

            return (
                <section id="create-page" className="flex flex-col font-sans mx-20 mb-36">
                    <h3 className="text-white max-lg:text-4xl max-md:text-4xl text-5xl">Join A Match</h3>
                </section>
            )
        } else if (location === 'my-matches') {

            return (
                <section id="create-page" className="flex flex-col font-sans mx-20 mb-36">
                    <h3 className="text-white max-lg:text-4xl max-md:text-4xl text-5xl">My Matches</h3>
                </section>
            )
        }
    })

    // console.log('getHeight', getHeight);

    return (
        <header id="head-bg" className="flex flex-col justify-between min-w-full" style={{ height: getHeight }}>
            <nav className="flex items-center leading-8 bg-blackOne text-white font-sans max-lg:text-xs text-sm mx-20 mt-6">
                <div className="flex w-4/5">
                    <div className="flex justify-center w-1/5 pr-4">
                        <img
                            src={iconGAP}
                            alt="GAP LOGO"
                            width={100}
                            height={100}
                            className="max-md:h-12"
                        />
                    </div>
                    <div id="left-menu" className="flex items-center w-4/5 space-x-4 ml-4">
                        <a href="/" className="">HOME</a>
                        <DropdownMenu />
                        <a href="/" className="max-lg:pl-0 pl-4">FEATURES</a>
                        <a href="docs" className="max-lg:pl-0 pl-4">DOCUMENTATION</a>
                    </div>
                </div>
                <div id="right-menu" className="flex justify-around w-1/5">
                    <MetamaskComp />
                    <a href="/">BNB</a>
                </div>
            </nav>
            <GetPathName />
        </header>
    )
})

export default HeaderComp;